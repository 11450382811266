<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card color="primary">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Send Push Notification
            </div>

            <div class="subtitle-1 font-weight-light">
              To android app
            </div>
          </template>

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="notification.to"
                    :error="$v.notification.to.$error"
                    :error-messages="!$v.notification.to.email ? 'Must be a valid email' : []"
                    counter
                    maxlength="64"
                    label="To"
                    class="purple-input"
                    hint="Leave blank to send to everyone with notifications enabled"
                    persistent-hint
                    autocomplete="off"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="notification.title"
                    :error="$v.notification.title.$error"
                    :error-messages="$v.notification.title.$error && !$v.notification.title.required ? 'Title is required' : []"
                    counter
                    maxlength="64"
                    autocomplete="off"
                    label="Title"
                    class="purple-input"
                  />
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    v-model="notification.body"
                    :error="$v.notification.body.$error"
                    :error-messages="$v.notification.body.$error && !$v.notification.body.required ? 'Message is required' : []"
                    counter
                    no-resize
                    maxlength="128"
                    class="purple-input"
                    label="Message"
                  />
                </v-col>

                <v-col cols="12">
                  <v-switch
                    v-model="notification.override"
                    color="red"
                    label="Override"
                    hint="Select to send to everyone including those with notifications disabled"
                    persistent-hint
                  />
                </v-col>

                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    :loading="isLoading"
                    class="ma-2"
                    tile
                    color="primary"
                    @click="sendNotification"
                  >
                    <v-icon left>
                      mdi-send
                    </v-icon> Send
                  </v-btn>
                </v-col>
                <v-col>
                  <v-alert
                    v-model="alert"
                    border="left"
                    close-text="Close Alert"
                    :type="status"
                    dismissible
                  >
                    {{ alertMessage }}
                  </v-alert>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import { required, maxLength, email } from 'vuelidate/lib/validators'
  import { redirectMixin } from '@/mixins/redirectMixin'

  export default {
    mixins: [redirectMixin],
    data () {
      return {
        notification: {
          to: [],
          title: '',
          body: '',
          override: false,
        },
        alert: false,
        alertMessage: '',
        status: 'info',
      }
    },
    computed: {
      ...mapState({
        message: state => state.notificationsData.message,
        error: state => state.notificationsData.error,
        isLoading: state => state.notificationsData.isLoading,
        currentUser: state => state.userData.currentUser,
      }),
    },
    validations: {
      notification: {
        to: { email, maxLength: maxLength(64) },
        title: { required, maxLength: maxLength(64) },
        body: { required, maxLength: maxLength(128) },
      },
    },
    watch: {
      message: function () {
        if (this.message === 'Push notification sent') {
          this.status = 'success'
          this.alert = true
          this.alertMessage = this.message
        }
      },
      error: function () {
        if (this.error && this.error.statusCode === 401) {
          const { email } = this.currentUser

          this.redirectToSignin(email, 'Kindly log in again, your session may have expired or you may have logged out from another device')
        } else {
          this.status = 'error'
          this.alert = true
          this.alertMessage = this.error
        }
      },
    },
    methods: {
      ...mapActions('notificationsData', ['sendPushNotification']),
      sendNotification () {
        const { token } = this.currentUser
        const { to, title, body, override } = this.notification

        const emailArray = to ? [to] : []

        this.$v.$touch()
        if (!this.$v.$invalid) {
          this.sendPushNotification({
            token,
            notification: {
              to: emailArray,
              title,
              body,
              override,
            },
          })
        }
      },
    },
  }
</script>
